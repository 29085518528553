import React from "react";
import MainLayout from "../layouts/MainLayout";

const ErrorPage = () => (
  <MainLayout>
    <h1>404: Not Found</h1>
  </MainLayout>
);

export default ErrorPage;
